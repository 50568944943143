<template>
  <div>
    <span
      v-show="!isFeedbackComponentActive"
      class="feedback-tag-position feedback-style rotated"
      style="cursor: pointer"
      @click="startFeedback"
    >
      <p class="has-text-weight-semibold">{{ $t('Feedback') }}</p>
    </span>
    <div>
      <b-modal
        :active.sync="isFeedbackComponentActive"
        trap-focus
        custom-class="form-height"
      >
        <feedback
          v-if="isFeedbackComponentActive"
          style="justify-content: center"
          :screen="screen"
          @feedback="propagateFeedback"
          @close="isFeedbackComponentActive = !isFeedbackComponentActive"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    Feedback: () =>
      import(
        /* webpackPrefetch: true */ '@/components/optional/feedback/Feedback'
      ),
  },
  data() {
    return {
      isFeedbackComponentActive: false,
      screen: '',
    };
  },
  watch: {
    isFeedbackComponentActive: {
      handler: function (newVal) {
        this.$emit('feedback-active', newVal);
      },
    },
  },
  methods: {
    startFeedback() {
      this.screen = 'rating';
      this.isFeedbackComponentActive = true;
    },
    propagateFeedback(evt) {
      this.$emit('feedback', evt);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.feedback-tag-position {
  z-index: 998;
  position: fixed;
  right: 0;
  top: 57%;
  margin-top: -3em;
  padding-top: 1em;
  padding-bottom: 1em;
  border-right: none;
}
.feedback-style {
  border-radius: 0px 5px 5px 0px;
  background-color: $feedback-handle;
  color: $feedback-handle-text;
  width: 2em;
  font-weight: 500;
}
.rotated {
  -webkit-writing-mode: vertical-rl;
  -moz-writing-mode: vertical-rl;
  -ms-writing-mode: vertical-rl;
  -o-writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}
.form-height {
  height: 100vh;
  z-index: 999;
}
</style>
